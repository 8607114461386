<template>
  <keep-alive>
	<div  v-loading="loading" :class="themeClass" class="max-width1100 margin-t-20 min-width1100" style='z-index: 10;'>
		<div class="flex-row border-bottom-theme ">
			<div class="tab-box text-center font-size13" :class="item.checked ? 'background-color-theme' : 'pointer-color-theme'"
			 v-for="(item, index) of tabData" :key="index" @click="tabClick(item.id)">
				{{ item.name }}
			</div>
		</div>
		<div class="padding-lr-15 padding-t-15 border-F2F2F2 flex-colum ">
			<div class="flex-row-space-between margin-b-15">
				<div class="flex-row">
					<div class="goodsTab-box text-center font-size13 pointer" :class="{ 'border-r-F2F2F2': goodsTabData.length == index + 1, 'color-theme': item.id==sortType }"
					 v-for="(item, index) of goodsTabData" :key="index" @click="goodsTabClick(item)">
						{{ item.name }}
						<span :class="item.checked ? 'el-icon-top' : ''"></span>
						<span :class="item.checked ? '' : 'el-icon-bottom'"></span>
					</div>
				</div>
				<div class="flex-row margin-tb-auto">
					<div>{{ shopListData.length }}家店铺</div>
					<div class="el-icon-arrow-left margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('-')"></div>
					<div>
						<span class="color-theme margin-r-5">{{ currentPage }}</span>
						<span>/</span>
						<span>{{ Math.ceil(total / pagesize) }}</span>
					</div>
					<div class="el-icon-arrow-right margin-tb-auto margin-rl-10 pointer-color-theme" @click="pageChange('+')"></div>
				</div>
			</div>
			<div class="flex-row-align-center shop-box margin-b-13 border-F2F2F2 radius5 " v-for="(item, index) of shopListData"
			 @click="gotoStoreList(item.shop.fShopUnitID)">
				<div class="shop-left">
					<div class="flex-column-center-center">
						<div class="img-box border-E4E4E4 flex-row-center-center radius5 margin-b-10 bg-fff">
							<img :src="item.shop.fShopLogoPath==''?imgurl:item.shop.fShopLogoPath" alt="" class="img-style" /></div>

						<div class="font-size13 twoline-show-hide margin-b-5" style="line-height: 20px;" :title="item.shop.fShopName">{{ item.shop.fShopName }}</div>
					</div>
					<div class="flex-row font-size12 margin-l-10 margin-b-10">
						<!-- <div class="tag-box1 bg-FF0000 text-center radius2 font-color-fff margin-r-5">店铺</div> -->
						<div class="font-color-666">{{ item.shop.followDetil!=null?item.shop.followDetil.focus:0}}人关注</div>
						<div class="font-color-666 margin-l-10">好评率{{item.shop.fEvaluationScore}}%</div>
					</div>
					<div>
						<div class="tag-box1 bg-FF0000 text-center radius2 font-color-fff margin-r-5" v-if="item.shop.fShopTypeID == 1">{{item.shop.fShopTypeName}}</div>
					</div>
				</div>
				<div class="flex-row-space-between" style="width: 100%;">
					<div class="flex-row">
						<div class="shop-right flex-row-space-between pointer" :class="item.recommendgoods.length == index1 ? '' : 'margin-r-30'"
						 v-for="(item1, index1) of item.recommendgoods" :key="index1">
							<div class="flex-colum" @click="gotoGoodDetil(item1.fGoodsID)">
								<div class="img-box1 border-E4E4E4 flex-row-center-center radius5 margin-b-10 bg-fff position-relative">
									<img :src="item1.fPicturePath" alt="" class="img-style1" />
									<!-- 0右上角 -->
									<div class="box-one-row-buoy" v-if="item1.couponInfo != null&& item1.couponInfo.fIconPath != undefined && item1.couponInfo.fDispArea == 0 && item1.couponInfo.fIconPath != '' && item1.couponInfo.fIconPath != null">
										<img :src="item1.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
									</div>
									<!-- 1右下角 -->
									<div class="box-one-row-buoy1" v-if="item1.couponInfo != null&& item1.couponInfo.fIconPath != undefined && item1.couponInfo.fDispArea == 1 && item1.couponInfo.fIconPath != '' && item1.couponInfo.fIconPath != null">
										<img :src="item1.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
									</div>
									<!-- 2左下角 -->
									<div class="box-one-row-buoy2" v-if="item1.couponInfo != null&& item1.couponInfo.fIconPath != undefined && item1.couponInfo.fDispArea == 2 && item1.couponInfo.fIconPath != '' && item1.couponInfo.fIconPath != null">
										<img :src="item1.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
									</div>
									<!-- 3左上角 -->
									<div class="box-one-row-buoy3" v-if="item1.couponInfo != null&& item1.couponInfo.fIconPath != undefined && item1.couponInfo.fDispArea == 3 && item1.couponInfo.fIconPath != '' && item1.couponInfo.fIconPath != null">
										<img :src="item1.couponInfo.fIconPath" alt="" style="width: 100%;height: 100%;">
									</div>
									<!--是否已售罄-->
									<div v-if='item1.fStatus == 2 ? true:false' class='box-sellout'  >
										<img src="@/assets/sellout.png" alt="" style="width: 100%;height: 100%;">
									</div>

								</div>
								<div class="font-size12 hide-text shopname-box" :title="item1.fGoodsFullName" style="text-align: center;">{{ item1.fGoodsFullName }}</div>
								<div class="font-color-FF0000" style="text-align: center;">
									¥<span class="font-weight700"><span v-if='item1.fGoodsMinPrice == item1.fGoodsPrice ? false:true'>{{ item1.fGoodsMinPrice }}~</span><span>{{ item1.fGoodsPrice }}</span>  </span>
									<span class="margin-r-5">元/{{ item1.fUnitName }}</span>
									<span class="font-color-999 line-through">{{ item1.yuanjia }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="shopBtn-box border-theme color-theme radius30 text-center font-size13 pointer ">进店</div>
				</div>
			</div>
			<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
				 :pagesize="pagesize" :total="total"></pagination>
			</div>
		</div>
	</div>
    </keep-alive>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import pagination from '@/components/pagination/pagination.vue';
	export default {
		components: {
			pagination
		},
		data() {
			return {
				imgurl: require("@/assets/shoplogo.png"),
				tabData: [{
					id: 2,
					name: '店铺',
					checked: true
				}],
				sortType: 1,
				goodsTabData: [{
						id: 1,
						name: '综合排序',
						checked: false
					},
					{
						id: 2,
						name: '销量排序',
						checked: true
					},

				],
				shopListData: [],
				total: 0, //总条数
				pagesize: 15, //每页显示条目个数
				currentPage: 1, //当前页
				isCheck: true,
				fSortMode: 2,
        loading: true,
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.getShopList();
		},
		methods: {
			gotoGoodDetil(id) {
				this.$router.push({
					name: 'GoodsDetails',
					query: {
						fGoodsID: id
					}
				})
				console.log(this.fGoodsID, "this.fGoodsID", )
			},
			getShopList() {
				let fGovCode = ''
				if (sessionStorage.getItem('district')) {
				  let district =  JSON.parse(sessionStorage.getItem('district'))
				  fGovCode = district.code
				} else {
				  let city = JSON.parse(sessionStorage.getItem('city'))
				  fGovCode = city.code
				}
				this.shopListData = []
				this.ApiRequestPost('api/mall/ebsale/goods-price/get-shop-and-goods-by-sort-new', {
					adcode: fGovCode,
					city: "",
					flng: 0,
					flat: 0,
					fSortType: this.sortType,
					fSortMode: this.fSortMode,
					maxResultCount: this.pagesize,
					filter: '',
					sorting: '',
					skipCount: (this.currentPage - 1) * this.pagesize
				}).then(
					res => {
						this.total = res.obj.totalCount;
						for (let item of res.obj.items) {
							if (item.recommendgoods.length > 5) {
								item.recommendgoods = item.recommendgoods.slice(0, 6);
							}
						}
						this.shopListData = res.obj.items;
            this.loading = false;
					},
					error => {
						// this.$message({
						// 	type: 'error',
						// 	message: 'error.message'
						// });
					});
			},
			gotoStoreList(id) {
				this.$router.push({
					name: 'storeList',
					query: {
						id: id
					}
				});
			},
			//tab点击事件
			tabClick(id) {},
			//换页、
			pageChange(type) {
				if (type == '+') {
					if (this.currentPage < Math.ceil(this.total / this.pagesize)) {
						this.currentPage++;
						this.getShopList();
					}
				} else {
					if (this.currentPage > 1) {
						this.currentPage--;
						this.getShopList();
					}
				}
			},
			//商品tab点击事件
			goodsTabClick(item) {

				this.sortType = item.id;
				item.checked = !item.checked;
				this.fSortMode = (item.checked ? 1 : 2);
				this.getShopList();
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			//currentPage（当前页数） 改变时会触发
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.getShopList();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.tab-box {
		height: 35px;
		line-height: 35px;
		width: 70px;
	}

	.goodsTab-box {
		width: 95px;
		height: 35px;
		line-height: 35px;
		border-left: 1px solid #f2f2f2;
		border-top: 1px solid #f2f2f2;
		border-bottom: 1px solid #f2f2f2;
	}

	.shop-box {
		height: 175px;
	}

	.shop-box:hover {
		background-color: #f2f4fd;
	}

	.shop-left {
		width: 199px;
		height: 175px;
		// padding-right: 38px;
		margin-right: 34px;
		// border-right: 1px solid #f2f2f2;
		background-color: #F8F9FE;
		text-align: center;
	}

	.img-box {
		width: 50px;
		height: 50px;
		margin-top: 10px;
	}

	.img-style {
		width: 52px;
		height: 52px;
	}

	.tag-box1 {
		padding: 1px 1px;
		width: 70px;
		margin: 0 auto;

	}

	.shop-right {
		width: 97px;
	}

	.img-box1 {
		width: 97px;
		height: 97px;
	}

	.img-style1 {
		width: 89px;
		height: 89px;
	}

	.shopname-box {
		width: 97px;
	}

	.shopBtn-box {
		width: 75px;
		height: 24px;
		line-height: 24px;
		margin-right: 15px;
	}
	.box-one-row-buoy {
		min-width: 40px;
		width: 40px;
		height: 40px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
	}
	.box-one-row-buoy1{
		min-width: 40px;
		width: 40px;
		height: 40px;
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy2{
		min-width: 40px;
		width: 40px;
		height: 40px;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 10;
	}
	.box-one-row-buoy3{
		min-width: 40px;
		width: 40px;
		height: 40px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
	}
	.box-sellout{
		min-width: 30px;
		width: 30px;
		height: 30px;
		position: absolute;
		right:10px;
		top: 10px;
		z-index: 100;
		line-height: 24px;
	}
</style>
